// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhatWeProvideCard {
  width: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
  align-items: center;
}
.WhatWeProvideCard__soon {
  padding: 8px 16px;
  border-radius: 48px;
  background: #ffa17a;
  border: 1px solid #ffffff;
}
.WhatWeProvideCard__subtitle {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #1d2026;
}
.WhatWeProvideCard img {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/WhatWeProvide/components/WhatWeProvideCard/WhatWeProvideCard.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;AAEE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AAAJ;AAGE;EACE,YAAA;AADJ","sourcesContent":[".WhatWeProvideCard {\n  width: fit-content;\n  display: flex;\n  flex-direction: column;\n  row-gap: 16px;\n  justify-content: center;\n  align-items: center;\n\n  &__soon {\n    padding: 8px 16px;\n    border-radius: 48px;\n    background: #ffa17a;\n    border: 1px solid #ffffff;\n  }\n\n  &__subtitle {\n    font-family: Mulish;\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 32px;\n    text-align: center;\n    color: #1d2026;\n  }\n\n  img {\n    width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
