import React from 'react';

import './WhatWeProvide.scss';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import WhatWeProvideCard from './components/WhatWeProvideCard/WhatWeProvideCard';

import Bike from '../../../../assets/images/Main/WhatWeProvide/Bike1.png';
import Bike2 from '../../../../assets/images/Main/WhatWeProvide/Bike2.png';
import Bike3 from '../../../../assets/images/Main/WhatWeProvide/Bike3.png';
import { Headline2 } from '../../../../components/Text/Headlines/Headlines';

const WhatWeProvide = () => {
  return (
    <div className="WhatWeProvide">
      <PageContainer>
        <Headline2>What we provide</Headline2>
        <div className="WhatWeProvide__cards">
          <WhatWeProvideCard image={Bike} subtitle={'E-Bike'} />
          <WhatWeProvideCard image={Bike2} subtitle={'E Road Bike'} />
          <WhatWeProvideCard image={Bike3} subtitle={'Road Bike'} />
        </div>
      </PageContainer>
    </div>
  );
};

export default WhatWeProvide;
